import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const pointzero = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Point zéro" />
    <h3 className='underline-title'>Point zéro</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p className="font-italic">
        (Aux citoyens Angolais)
      </p>
      <p>
        Une clameur fuse au désert<br />
        Elle est audible jusqu'à l'infini du cosmos<br />
        Le manteau endeuillé du nimbus est aperçu<br />
        Préfaçant ainsi le funeste nuage aux grêles ravageuses<br />
        L'ouragan souffle tous ses vents sinistres<br />
        Dévastant de ses violents tourbillons<br />
        Le jardin millénaire aux sources thermales<br />
        La pluie tombe sa dernière goutte vitale<br />
        Le soleil luit de sa dernière lumière<br />
        Le lion guele son dernier rugissement<br />
        Ultimes annonces du début de la première fin<br />
        Le cobra siffle et injecte le venin de la mort<br />
        Les ténébres blanches sèment les ruines<br />
        L'Angola se déchire de tous ses déchirements<br />
        La renaissance à la vie sourveraine?<br/>
      </p>
    </div>
    <hr/>
    <p className="date-published">Bukavu, le 10 novembre 1975</p>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default pointzero
